import "../styles/Matches.css";
import Calendar from "../Components/Calendar";

const Matches = ({ user }) => {
    return (
        <div className='page--matches'>
            <h3 className="matches--title">Přihlas se kdy si chceš odehrát</h3>
            <Calendar
                user={user}
            />
        </div>
    );
};

export default Matches;
