import '../styles/PlayersList.css';
import '../styles/Typography.css';

const PlayersList = ({ players }) => {
    return (
        <section className='row--playersList'>
            <h3>Seznam hráčů:</h3>
            <table>
                <thead>
                    <tr>
                        <th>Přezdívka:</th>
                        <th>Zvolené hry:</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player, i) => (
                        <tr key={i} style={{ color: player.color }}>
                            <td>{player.name}</td>
                            <td className='player-gamesList'>
                                {player?.games.map((game, j) => (
                                    <img
                                        key={j}
                                        src={`/img/${game.toLowerCase().replace(/ /g, "-")}-logo.png`}
                                        alt={game}
                                    />
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>

    )
}

export default PlayersList