import lebronImage from '../img/lebron-james.png';
import messiImage from '../img/lionel-messi.png';
import '../styles/Hero.css';

const Hero = ({ user }) => {
    return (
        <section className='row--hero'>
            <img src={lebronImage} alt="lebron" />
            <img src={messiImage} alt="messi" />
            <div className='hero--text'>
                <h1>PlayStation</h1>
                <h2 className='neon-text'>Tournament</h2>
            </div>
            {!user?.name ? (
                <a className='btn--join' href='/#form--register'>Chci hrát</a>
            ) : null}
        </section>
    )
}

export default Hero