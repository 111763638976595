import { useEffect, useRef, useState } from 'react'; // Import useState for controlled inputs
import { gamesList } from './GamesList';
import axios from 'axios';
import '../styles/User.css';

const User = ({ user, setPlayers, players, saveUser }) => {
    const usermenuRef = useRef(null);
    const [userName, setUserName] = useState(user?.name || ""); // Přidání stavu pro přezdívku uživatele
    const [userColor, setUserColor] = useState(user?.color || "#000000"); // Přidání stavu pro barvu uživatele
    const [userGames, setUserGames] = useState(user?.games || []); // Přidání stavu pro hry
    const [loginName, setLoginName] = useState("") // Jméno pro přihlášení
    const [error, setError] = useState(""); // Ukládá chybové zprávy

    useEffect(() => {
        if (user) {
            setUserName(user.name || "");
            setUserColor(user.color || "#000000");
            setUserGames(user.games || []);
        }
    }, [user]);

    useEffect(() => {
        console.log("players: ", players)
    }, [players])

    useEffect(() => {
        console.log("User: ", user)
    }, [user, loginName])

    const dropMenuHandler = () => {
        usermenuRef.current.classList.toggle('usermenu--show');
    };

    const handleCheckboxChange = (game, isChecked) => {
        if (isChecked) {
            setUserGames((prevGames) => [...prevGames, game.title]); // Přidá hru do seznamu
        } else {
            setUserGames((prevGames) => prevGames.filter((g) => g !== game.title)); // Odebere hru ze seznamu
        }
    };

    const loginUserHandler = (e) => {
        e.preventDefault()

        // ** Validace formuláře **
        if (!loginName || loginName.length < 3) {
            setError("Přezdívka musí být minimálně 3 znaky dlouhá.");
            return;
        }
        if (loginName.length >= 20) {
            setError("Přezdívka může být dlouhá maximálně 20 znaků.");
            return;
        }

        for (let index = 0; index < players.length; index++) {
            if (players[index].name === loginName) {
                axios
                    .get(`https://psolympics-production.up.railway.app/players/${loginName}`, {
                        name: loginName
                    })
                    .then((response) => {
                        const newUser = response.data;

                        // Aktualizace aktuálního uživatele
                        saveUser(newUser);

                        // Synchronizace stavu komponenty
                        setUserName(newUser.name);
                        setUserColor(newUser.color);
                        setUserGames(newUser.games);
                    })
                    .catch((error) => {
                        console.error(error);
                        setError("Došlo k chybě při přihlášení. Zkuste to znovu.");
                    });
            }
            else {
                setError("Přezdívka neexistuje.");
            }
        }
    }

    const editPlayerHandler = (e) => {
        e.preventDefault();

        // ** Validace formuláře **
        if (!userName || userName.length < 3) {
            setError("Přezdívka musí být minimálně 3 znaky dlouhá.");
            return;
        }
        if (userName.length >= 20) {
            setError("Přezdívka může být dlouhá maximálně 20 znaků.");
            return;
        }
        for (let index = 0; index < players.length; index++) {
            if (user?.name !== userName && players[index].name === userName) {
                setError("Přezdívka již existuje. Zvolte jinou.");
                return;
            }
            if (user?.color !== userColor && players[index].color === userColor) {
                setError("Barva již byla vybrána. Zvolte jinou.");
                return;
            }
        }
        if (userGames.length === 0) {
            setError("Vyber si alespoň jednu hru.");
            return;
        }

        axios
            .patch(`https://psolympics-production.up.railway.app/players/${user.name}`, {
                name: userName,
                color: userColor,
                games: userGames,
            })
            .then((response) => {
                const updatedUser = response.data;

                // Aktualizace hráče v seznamu
                setPlayers((prevPlayers) =>
                    prevPlayers.map((player) =>
                        player.name === user.name ? updatedUser : player
                    )
                );

                // Aktualizace aktuálního uživatele
                saveUser(updatedUser);

                // Synchronizace stavu komponenty
                setUserName(updatedUser.name);
                setUserColor(updatedUser.color);
                setUserGames(updatedUser.games);

                // Vymazání chyb
                setError('');
            })
            .catch((error) => {
                console.error(error);
                setError("Došlo k chybě při registraci. Zkuste to znovu.");
            });
    };

    const logoutHandler = () => {
        localStorage.removeItem("user"); // Odstranění uživatele z localStorage
        window.location.reload(); // Znovu načte aktuální stránku
    };


    return (
        <div className='userWrapper'>
            {userName
                ? <h4
                    className='userName'
                    onClick={dropMenuHandler}
                    style={{ color: userColor }}
                >
                    {userName}
                </h4>
                : <form onSubmit={loginUserHandler}>
                    <input
                        value={loginName}
                        onChange={(e) => setLoginName(e.target.value)}
                    />
                    {error && <p className='error'>{error}</p>}
                    <button>Přihlásit se</button>
                </form>
            }
            <div ref={usermenuRef} className='user--menu'>
                <p>Nastavení hráče:</p>
                <form onSubmit={editPlayerHandler}>
                    <div className='edit-username'>
                        Přezdívka:
                        <input
                            type='text'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className='edit-color'>
                        Barva:
                        <input
                            type='color'
                            value={userColor}
                            onChange={(e) => setUserColor(e.target.value)}
                        />
                    </div>
                    Hry:
                    <div className="edit-games">
                        {gamesList.map((game, i) => (
                            <div key={i} className="game-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={userGames.includes(game)}
                                        onChange={(e) =>
                                            handleCheckboxChange(game, e.target.checked)
                                        }
                                    />
                                    <div
                                        className={`game-logo ${userGames.includes(game.title) ? "checked" : ""
                                            }`}
                                    >
                                        <img
                                            src={game.image}
                                            alt={game.title}
                                        />
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>
                    {error && <p className='error'>{error}</p>}
                    <button className='btn--update'>Odeslat</button>
                </form>
                <div className='remove-user' onClick={logoutHandler}>Odhlásit se</div>
            </div>
        </div>
    );
};

export default User;
