import React, { useEffect, useState } from 'react'
import ResultTable from '../Components/ResultTable'
import { gamesList } from '../Components/GamesList'
import axios from 'axios';

const Results = ({ players }) => {
    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedMatches, setSelectedMatches] = useState(null);
    const [matches, setMatches] = useState([])

    useEffect(() => {
        axios
            .get("https://psolympics-production.up.railway.app/match")
            .then((response) => {
                console.log("Nahrané zápasy: ", response.data);
                setMatches(response.data)
            })
            .catch((err) => console.error("Nepodařilo se načíst zápasy: ", err));

    }, [])

    useEffect(() => {
        setSelectedPlayers(players.filter(player =>
            player.games.includes(selectedGame)
        ))
        setSelectedMatches(matches.filter(match =>
            match.games.includes(selectedGame)
        ))
    }, [selectedGame, players, matches])

    const handleSelect = (title) => {
        setSelectedGame(title);
    };

    return (
        <div>
            <div className='results--filter'>
                {gamesList.map((game) => (
                    <label
                        key={game.title}
                        className={`game-option ${selectedGame === game.title ? "selected" : ""}`}
                    >
                        <input
                            type="radio"
                            name="game"
                            value={game.title}
                            checked={selectedGame === game.title}
                            onChange={() => handleSelect(game.title)}
                        />
                        <img src={game.image} alt={game.title} className="game-image" />
                    </label>
                ))}
            </div>
            <ResultTable
                players={selectedPlayers}
                matches={selectedMatches}
            />
        </div>
    )
}

export default Results