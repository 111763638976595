import { useState } from 'react';
import User from './User';
import '../styles/Header.css';
import { Link } from 'react-router-dom';

const Header = ({ user, players, setPlayers, saveUser }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <header>
            <Link to="/">
                <img src='/img/olympics-logo.png' alt='logo' />
            </Link>
            <div
                className={`hamburger-menu ${menuOpen ? 'open' : ''}`}
                onClick={toggleMenu}
                tabIndex="0"
            >
                <div className='hamburger-layer'></div>
                <div className='hamburger-layer'></div>
                <div className='hamburger-layer'></div>
            </div>

            <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
                <nav>
                    <Link onClick={toggleMenu} to="/games">Hry</Link>
                    {user?.name
                        ? <Link onClick={toggleMenu} to="/calendar">Kalendář</Link>
                        : <Link onClick={e => alert("Nejdřív se přihlaš")} >Kalendář</Link>}
                    <Link onClick={toggleMenu} to="/results">Výsledky</Link>
                </nav>
                <div className='header--login'>
                    <User
                        user={user}
                        players={players}
                        setPlayers={setPlayers}
                        saveUser={saveUser}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
