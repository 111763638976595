import { gamesList } from '../Components/GamesList'
import '../styles/Games.css'
const GamesPage = () => {
    return (
        <div className='page--games'>
            {gamesList.map((game, i) => (
                <div key={i} className='gameWrapper'>
                    <div className='game--text'>
                        <h3>{game.title}</h3>
                        <p>{game.desc}</p>
                    </div>
                    <iframe
                        title={game.title}
                        className='game---video'
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${game.video}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>

                </div>
            ))}</div>
    )
}

export default GamesPage