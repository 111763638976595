import { useState, useEffect } from "react";
import axios from "axios"; // Import axios
import "../styles/Matches.css";
import { gamesList } from "./GamesList";

const MatchModal = ({ user, day, hour, setMatches, closeModal }) => {
    const [matchGames, setMatchGames] = useState([]);
    const [players, setPlayers] = useState([]);
    const [thisMatch, setThisMatch] = useState([]);
    const [matchResult, setMatchResult] = useState("");
    const [error, setError] = useState(""); // Ukládá chybové zprávy
    const [success, setSuccess] = useState(""); // Ukládá chybové zprávy

    // Převod 'day' a 'hour' na správný formát pro Date
    const formatDateToISO = (day, hour) => {
        try {
            const [dayPart, monthPart, yearPart] = day.split(".");
            const formattedDate = `${yearPart}-${monthPart.trim().padStart(2, "0")}-${dayPart.trim().padStart(2, "0")}T${hour.trim()}`;
            return formattedDate.trim(); // Zajištění, že nebudou mezery
        } catch (error) {
            console.error("Chyba při formátování data:", error);
            return null;
        }
    };


    const matchDate = formatDateToISO(day, hour);

    useEffect(() => {
        axios
            .get(`https://psolympics-production.up.railway.app/match/${matchDate}`)
            .then((response) => {
                const matchData = response.data;
                setThisMatch(matchData);
                setPlayers(matchData.players || []); // Access directly from response
                setMatchGames(matchData.games || []); // Access directly from response
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log("Zápas nenalezen.");
                } else {
                    console.error(error);
                    setError("Došlo k chybě při načtení tohoto zápasu. Zkuste to znovu.");
                }
            });
    }, [matchDate]); // Keep dependencies clean


    const handleCheckboxChange = (game, isChecked) => {
        if (isChecked) {
            setMatchGames((prevGames) => [...prevGames, game]); // Přidá hru do seznamu
        } else {
            setMatchGames((prevGames) => prevGames.filter((g) => g !== game)); // Odebere hru ze seznamu
        }
    };

    const updatePlayers = () => {

        if (players.length > 1) {
            setError("Termín už je obsazený");
            return;
        }

        if (players.includes(user.name)) {
            setError("Už si se do tohoto zápasu přihlásil");
            return;
        }

        setPlayers((prevPlayers) => [...prevPlayers, user.name]);
    }

    const postNewMatch = () => {

        if (matchGames.length !== 1) {
            setError("Musíš zvolit JEDNU hru")
            return;
        }

        updatePlayers()

        // Odeslání dat na server
        axios
            .post("https://psolympics-production.up.railway.app/match", {
                matchDate,
                players: [...players, user.name], // Přidání hráče
                games: matchGames,
            })
            .then((response) => {
                setMatches((prevMatches) => {
                    const updatedMatches = [...prevMatches, response.data];
                    console.log("Zápas vytvořen: ", response.data);
                    setSuccess("Přihlásil si se k zápasu");
                    closeModal();
                    return updatedMatches;
                });
            })
            .catch((error) => {
                console.error(error);
                setError("Došlo k chybě při přidání zápasu. Zkuste to znovu.");
            });
    }

    const updateMatch = () => {

        if (matchGames.length !== 1) {
            setError("Musíš zvolit JEDNU hru")
            return;
        }

        updatePlayers();

        axios
            .patch(`https://psolympics-production.up.railway.app/match/${matchDate}`, {
                "players": [...players, user.name],
                "games": matchGames,
                "result": matchResult,
            })
            .then(response => {
                setSuccess("Zápas upraven");
                setMatchResult();
                console.log("Posláno k úpravě: ", {
                    "players": players,
                    "games": matchGames,
                    "result": matchResult,
                })
                console.log("Response: ", response.data)
                setThisMatch(response.data);
            })
            .catch((error) => {
                console.error(error);
                setError("Došlo k chybě při aktualizaci zápasu. Zkuste to znovu.");
            })
        return
    }
    const logoutUser = () => {
        const updatedPlayers = players.filter(player => player !== user.name);
        console.log("updatedPlayers: ", updatedPlayers)

        if (updatedPlayers.length < 1) {
            deleteMatch()
            return;
        }

        axios
            .patch(`https://psolympics-production.up.railway.app/match/${matchDate}`, {
                "players": updatedPlayers
            })
            .then(response => {
                console.log("players: ", players)
                setPlayers(updatedPlayers);
                setThisMatch(response.data);
                setSuccess("Úspěšně si se odhlásil");
                closeModal();
            })
            .catch((error) => {
                console.error(error);
                setError("Došlo k chybě při Odhlášení hráče. Zkuste to znovu.");
            })
        return
    }

    const deleteMatch = () => {
        console.log("Odesláno", thisMatch._id);

        axios
            .delete(`https://psolympics-production.up.railway.app/match/${thisMatch._id}`)
            .then(response => {
                console.log("Zápas", response.data, " odebrán");
                setSuccess("Zápas odebrán");
                setThisMatch([]);
                setMatches((prevMatches) => {
                    const updatedMatches = prevMatches.filter(match => match.matchDate !== response.data.match.matchDate)
                    console.log("Zápas vytvořen: ", response.data);
                    setSuccess("Přihlásil si se k zápasu");
                    closeModal();
                    return updatedMatches;
                });
                closeModal();
            }
            )
            .catch((error) => {
                console.error(error)
                setError("Došlo k chybě při smazání zápasu. Zkus to znovu")
            })
    }


    const setMatchToggler = (e) => {
        e.preventDefault(); // Zabrání reloadu stránky

        if (thisMatch && thisMatch._id) {
            updateMatch()
        } else {
            postNewMatch()
        }
    };

    return (
        <form onSubmit={setMatchToggler} className="match--modal">
            <h3>Detaily zápasu:</h3>
            <div className="match--player">
                Hráči:
                <h4>{players[0]} vs {players[1]}</h4>
            </div>
            {players.includes(user?.name) ?
                <div className="btn--logOut" onClick={logoutUser}>Odhlásit se</div> : null}
            <div className="match--date">
                <p>Datum: {day}</p>
                <p>Čas: {hour}</p> {/* Opraveno použití proměnné hour */}
            </div>
            <div className="gamesList">
                {gamesList.map((game, i) => (
                    <label key={i} className="game-item">
                        <input
                            type="checkbox"
                            checked={matchGames.includes(game.title)} // Checkbox je zaškrtnutý, pokud je hra v seznamu
                            onChange={(e) => handleCheckboxChange(game.title, e.target.checked)}
                        />
                        <img className={`game-logo ${matchGames.includes(game.title) ? "checked" : ""}`}
                            src={game.image}
                            alt={game.title}
                        />
                    </label>
                ))}
            </div>
            {success && <p className="success">{success}</p>} {/* Zobrazení chybové zprávy */}
            {error && <p className="error">{error}</p>} {/* Zobrazení chybové zprávy */}
            {!players.includes(user.name) ?
                <button type="submit" className="btn--setMatch">
                    Přidat se
                </button> : <button type="submit" className="btn--setMatch">
                    Upravit
                </button>
            }
            <div className="modal-close" onClick={closeModal}>
                X
            </div>
        </form>
    );
};

export default MatchModal;
