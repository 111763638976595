import { Outlet } from "react-router-dom";
import Header from "../Components/Header";

const Layout = ({ user, players, setPlayers, saveUser }) => {
    return (
        <>
            <Header
                user={user}
                players={players}
                setPlayers={setPlayers}
                saveUser={saveUser}
            />
            <Outlet />
        </>
    )
};

export default Layout;