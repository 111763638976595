import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import GamesPage from "./Pages/GamesPage";
import Results from "./Pages/Results";
import Matches from "./Pages/Matches";
import NoPage from "./Pages/NoPage";
import Layout from "./Pages/Layout";
import "./styles/index.css"

export default function App() {
  const [players, setPlayers] = useState([]);
  const [user, setUser] = useState(null);

  // Načtení hráčů
  useEffect(() => {
    axios
      .get("https://psolympics-production.up.railway.app/players")
      .then((response) => setPlayers(response.data))
      .catch((error) => console.error(error));

    // Načtení uživatele z localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Funkce pro uložení uživatele do localStorage a stavu
  const saveUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Layout
            user={user}
            players={players}
            setPlayers={setPlayers}
            saveUser={saveUser}
          />}>
          <Route index element={
            <Home
              user={user}
              players={players}
              setPlayers={setPlayers}
              saveUser={saveUser}
            />} />
          <Route path="games" element={
            <GamesPage />} />
          <Route path="results" element={
            <Results
              players={players}
            />} />
          <Route path="calendar" element={
            <Matches
              user={user}
            />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);