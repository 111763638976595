import axios from 'axios';
import { useEffect, useState } from "react";
import "../styles/Calendar.css";
import MatchModal from "./MatchModal"; // Import MatchModal komponenty

const Calendar = ({ user }) => {
    const [matches, setMatches] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null); // Vybraný den pro modální okno
    const [selectedHour, setSelectedHour] = useState(null); // Vybraný čas pro modální okno
    const [isModalOpen, setIsModalOpen] = useState(false); // Stav pro zobrazení modálního okna

    function generateDateArray(from, to) {
        const startDate = new Date(from);
        const endDate = new Date(to);
        const dateArray = [];

        while (startDate <= endDate) {
            const formattedDate = new Intl.DateTimeFormat('cs-CZ').format(startDate);
            dateArray.push(formattedDate);
            startDate.setDate(startDate.getDate() + 1);
        }

        return dateArray;
    }

    function generateTimeArray(h) {
        const hoursArray = [];
        for (let i = 15; i <= h; i++) {
            hoursArray.push(`${i}:00`);
        }
        return hoursArray;
    }

    const dates = generateDateArray('2024-12-10', '2025-01-31');
    const hours = generateTimeArray(24);

    const fetchMatches = () => {
        axios
            .get("https://psolympics-production.up.railway.app/match")
            .then((response) => {
                console.log("Nahrané zápasy: ", response.data);
                setMatches(response.data)
            })
            .catch((err) => console.error("Nepodařilo se načíst zápasy: ", err));
    }

    useEffect(() => {
        fetchMatches()
    }, []);

    const generateMatch = (date, time) => {
        // Rozdělit datum na den, měsíc a rok
        const [day, month, year] = date.split('.').map(part => part.trim());

        // Složit datum a čas do formátu ISO
        const combinedDateTime = `${year}-${month}-${day}T${time}`;

        // Filtrovat zápasy podle zadaného data a času
        const match = matches.filter(m => m.matchDate === combinedDateTime);

        // Ověřit, zda byl nalezen zápas
        if (match.length > 0) {
            const player1 = match[0]?.players?.[0] || "";
            const player2 = match[0]?.players?.[1] || ".........";
            const game = match[0]?.games?.[0] || "";

            return (
                <span className='match--details'>
                    <img
                        className='match--game'
                        src={`/img/${game.toLowerCase().replace(/ /g, "-")}-logo.png`}
                        alt={game}
                    />
                    <span className='player--1'>
                        {player1}
                    </span>
                    vs
                    <span className='player--2'>
                        {player2}
                    </span>
                </span>
            );
        }

        // Pokud není zápas nalezen, vrátit prázdný obsah
        return null;
    };

    const showModalToggler = (day, hour) => {
        setSelectedDay(day); // Nastavení vybraného dne
        setSelectedHour(hour); // Nastavení vybraného času
        setIsModalOpen(true); // Zobrazení modálního okna
    };

    const closeModal = () => {
        setIsModalOpen(false); // Zavření modálního okna
        fetchMatches()
    };

    return (
        <div className="calendarWrapper">
            {dates.map((day, i) => (
                <div key={i} className="calendar--day">
                    <div className="day-date">{day}</div>
                    {hours.map((hour, i) => (
                        <button
                            key={i}
                            className="day-hour"
                            onClick={() => showModalToggler(day, hour)}> {/* Předání dne a času */}
                            <span className='hour'>{hour}</span>
                            {generateMatch(day, hour)}
                        </button>
                    ))}
                </div>
            ))}

            {/* Zobrazení modálního okna */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <MatchModal
                        user={user}
                        day={selectedDay}
                        hour={selectedHour}
                        closeModal={closeModal}
                        setMatches={setMatches}
                    />
                </div>
            )}
        </div>
    );
};

export default Calendar;
