import { useState, useEffect } from 'react';
import axios from 'axios';
import { gamesList } from './GamesList';
import '../styles/RegisterForm.css';

const RegisterForm = ({ setPlayers, players, saveUser }) => {
  const [name, setName] = useState("");
  const [color, setColor] = useState('');
  const [games, setGames] = useState([]);
  const [error, setError] = useState(""); // Ukládá chybové zprávy
  const [availableColors, setAvailableColors] = useState([
    "#FF0000",  // Červená
    "#FF7F00",  // Oranžová
    "#FFD700",  // Zlatá
    "#FFFF00",  // Žlutá
    "#ADFF2F",  // Světle zelená
    "#32CD32",  // Limetková
    "#00FF00",  // Zelená
    "#00FA9A",  // Medium spring green
    "#00CED1",  // Dark turquoise
    "#40E0D0",  // Turquoise
    "#1E90FF",  // Dodger blue
    "#6495ED",  // Kornflower blue
    "#FF1493",  // Deep pink
    "#FF69B4",  // Hot pink
    "#BA55D3",  // Medium orchid
    "#8A2BE2",  // Blue violet
    "#F08080",  // Light coral
    "#F0E68C",  // Khaki
    "#D2691E",  // Chocolate
    "#A52A2A"   // Hnědá
  ]
  );

  useEffect(() => {
    for (let i = 0; i < players.length; i++) {
      setAvailableColors(prevColors => prevColors.filter(c => c !== players[i].color)); // Odebrání barvy        
    }
  }, [players]);


  const handleCheckboxChange = (game, isChecked) => {
    if (isChecked) {
      setGames((prevGames) => [...prevGames, game]); // Přidá hru do seznamu
    } else {
      setGames((prevGames) => prevGames.filter((g) => g !== game)); // Odebere hru ze seznamu
    }
  };

  const addPlayerHandler = (e) => {
    e.preventDefault();

    // ** Validace formuláře **
    // Přezdívka musí být jedinečná a minimálně 3 znaky
    if (!name || name.length < 3) {
      setError("Přezdívka musí být minimálně 3 znaky dlouhá.");
      return;
    }
    // Přezdívka musí mít maximálně 20 znaků
    if (!name || name.length >= 20) {
      setError("Přezdívka může být dlouhá maximálně 16 znaků.");
      return;
    }
    for (let index = 0; index < players.length; index++) {
      if (players[index].name === name) {
        setError("Přezdívka již existuje. Zvolte jinou.");
        return;
      }
      if (players[index].color === color) {
        setError("Barva již byla vybrána. Zvolte jinou.");
        return;
      }
    }

    // Barva musí být vybraná a jedinečná
    if (!color) {
      setError("Musíte si vybrat barvu.");
      return;
    }
    // Vyber si alespoň jednu hru
    if (games.length < 1) {
      setError("Vyber si alespoň jednu hru");
      return;
    }


    // Pokud vše projde, odešleme data na server
    axios
      .post("https://psolympics-production.up.railway.app/players", { name, color, games })
      .then((response) => {
        setPlayers((prevPlayers) => {
          const updatedPlayers = [...prevPlayers, response.data];
          // After players are updated, we can call saveUser
          saveUser({ name, color, games });
          return updatedPlayers;
        });

        // Reset form and errors
        setName('');
        setColor('');
        setError('');
      })
      .catch((error) => {
        console.error(error);
        setError("Došlo k chybě při registraci. Zkuste to znovu.");
      });
  };

  const colorPickHandler = (clr) => {
    setColor(clr);
  };

  return (
    <section id='form--register' className='row--form--join neon-card' style={{ '--_clr-neon': color }}>
      <h3 className='neon-text' style={{ '--_clr-neon': color }}>Registrační formulář</h3>
      <form onSubmit={addPlayerHandler}>
        <div>
          <label htmlFor="nickname">Přezdívka</label>
          <input
            id="nickname"
            type="text"
            placeholder="Zadejte přezdívku"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="color">Zvol svou barvu</label>
          <div className='colorsList'>
            {availableColors.map((clr, i) => (
              <div
                key={i}
                className={`color-box ${color === clr ? 'selected' : ''}`}
                style={{ background: clr }}
                onClick={() => colorPickHandler(clr)}
              ></div>
            ))}
          </div>
        </div>
        <div>
          <label>V jakých hrách budeš soutěžit?</label>
          <div className="gamesList">
            {gamesList.map((game, i) => (
              <div key={i} className="game-item">
                <label>
                  <input
                    type="checkbox"
                    checked={games.includes(game.title)} // Checkbox je zaškrtnutý, pokud je hra v seznamu
                    onChange={(e) => handleCheckboxChange(game.title, e.target.checked)}
                  />
                  <span className={`game-logo ${games.includes(game.title) ? "checked" : ""}`}>
                    <img
                      src={game.image}
                      alt={game.title}
                    />
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        {error && <p className='error'>{error}</p>} {/* Zobrazení chybové zprávy */}
        <button className='btn--register'>Odeslat</button>
      </form>
    </section>
  );
};

export default RegisterForm;
