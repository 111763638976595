import '../styles/Results.css'

const ResultTable = ({ players, matches }) => {

    const getResult = (player1, player2) => {

        const p1W = player1.name;
        const p2W = player2.name;

        const result = `${p1W}:${p2W}`;
        return result
    }

    return (
        <table className='result--table'>
            <thead>
                <tr>
                    <th>#</th>
                    {players.map((player, i) => (
                        <th key={i}>{player?.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {players.map((player1, i) => (
                    <tr key={i}>
                        <td>{player1.name}</td>
                        {players.map((player2, j) => (
                            <td key={j}>
                                {i === j ? '-' : getResult(player1, player2)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ResultTable