import { useRef } from 'react';
import '../styles/Information.css';

const Information = () => {
    const infoRef = useRef(null);

    const showInfoHandler = () => {
        infoRef.current.classList.toggle('info--show');
    }

    return (
        <section ref={infoRef} className='row--info info--show'>
            <h3 onClick={showInfoHandler}>Pravidla turnaje:</h3>
            <div className='overflow'>
                <ul className='info-list'>
                    <li>Registruj se dole ve formuláři do <u>10.prosince 2024</u></li>
                    <li>Po ukončení registrací se tady na stránce přihlaš na volný termín v kalendáři kdy si přijdeš zahrát zápas</li>
                    <li>Turnaj se bude hrát na kola, počet kol bude podle toho kolik budeme hrát různých her</li>
                    <li>Bodování za výhru jsou 3 body, za remízu 1 bod</li>
                    <li>Hrát se bude na adrese Tournament mastera: Bulharská 936, Pardubice</li>
                    <li>Tournament se hraje o věcné ceny, budeme rádi za sponzorské dary</li>
                    <li>Vyhrává te, kdo má na konci turnaje nejvíce bodů, v případě remízy se bude hrát rozhodovací zápas</li>
                    <li>Turnaj končí nejpozději 30.1.2025</li>
                    <li>Pravidla se můžou v průběhu turnaje měnit</li>
                </ul>
            </div>
        </section>
    )
}

export default Information