import '../styles/Home.css';
import '../styles/Typography.css';
import RegisterForm from '../Components/RegisterForm';
import PlayersList from '../Components/PlayersList';
import Information from '../Components/Information';
import GamesList from '../Components/GamesList';
import Hero from '../Components/Hero';

function Home({ user, players, setPlayers, saveUser }) {

    return (
        <div className="Home">
            <Hero user={user} />
            <GamesList />
            <Information />
            {/* Pokud uživatel ještě není registrován, zobrazí se registrační formulář */}
            {!user?.name ? (
                <RegisterForm
                    players={players}
                    setPlayers={setPlayers}
                    saveUser={saveUser}
                />
            ) : null}
            <PlayersList players={players} />
            <section className='row--footer'></section>
        </div>
    );
}

export default Home;
