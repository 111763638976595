import '../styles/Games.css';

export const gamesList = [
    {
        title: "Fifa",
        image: "/img/fifa-logo.png",
        desc: "FIFA 2020 je populární fotbalový simulátor, který nabízí autentický zážitek z ligového i mezinárodního fotbalu. Hra obsahuje realistické grafické zpracování, vylepšené ovládání a rozmanité herní módy, jako je kariéra nebo FIFA Ultimate Team.",
        video: "2-JQI0A53jM",
    },
    {
        title: "NBA2K",
        image: "/img/nba2k-logo.png",
        desc: "NBA 2K23 přináší realistické basketbalové zážitky s aktualizovanými hráčskými soupiskami, autentickou grafikou a pokročilou simulací. Hra nabízí řadu módů, od kariérního příběhu po online ligy, a je ideální pro fanoušky NBA.",
        video: "8QrUzf-ox80",
    },
    {
        title: "NHL",
        image: "/img/nhl-logo.png",
        desc: "NHL 2020 je oblíbený hokejový simulátor s realistickým zpracováním herních mechanik, grafiky a atmosféry. Hráči si mohou zahrát kariérní mód, online zápasy nebo vytvořit vlastní tým a vyrazit na led.",
        video: "0HNXHnxOWeU",
    },
    {
        title: "UFC",
        image: "/img/ufc-logo.png",
        desc: "UFC 3 2K je profesionální bojová hra, která přináší pohlcující zážitek z wrestlingových zápasů. S širokou škálou bojovníků, různými herními módy a realistickými pohyby je ideální volbou pro fanoušky MMA.",
        video: "ZCEFD5jJU4U",
    },
    {
        title: "Tony Hawk",
        image: "/img/tony-hawk-logo.png",
        desc: "Tony Hawk's Pro Skater je ikonická skateboardingová hra, která kombinuje realistické triky s arkádovou zábavou. Hra nabízí nostalgické tratě i nové prvky, ideální pro všechny milovníky skateboardingu.",
        video: "Q6gnJ5ypkGU",
    },
    {
        title: "Crash Team racing",
        image: "/img/crash-team-racing-logo.png",
        desc: "Crash Team Racing je zábavná závodní hra, ve které se hráči vydávají na bláznivé tratě plné překážek a power-upů. S oblíbenými postavami z universa Crashe Bandicoota je hra skvělá pro celou rodinu.",
        video: "EcCzAvLziZc",
    }
];

const GamesList = () => {

    return (
        <div className='games--list'>
            {gamesList.map((game, i) => (
                <img
                    key={i}
                    src={game.image}
                    alt={game.title}
                    className='game'
                />
            ))}
        </div>
    )
}

export default GamesList